import styled from "styled-components/macro";
import { Typography } from "@material-ui/core";

import LiveChatAdImage from "@/assets/images/live-chat-ad.png";
import theme from "@/theme";
import store from "@/store";

const LiveCircleAdContainer = styled.div`
  position: absolute;
  right: 3%;
  bottom: 12%;
  zindex: 10000000;
  pointer-events: all;
  width: 100px;
  height: 100px;

  @media screen and (${theme.breakpoint.tablet.down}) {
    width: 80px;
    height: 80px;
    padding: 16px;
    bottom: 12%;
  }
`;

const LiveCopy = styled(Typography)`
  padding-inline: 0.8rem;
  padding-block: 0.4rem;
  width: fit-content;
  border-radius: 2rem;
  color: white;
  background-color: #00bb00;
  position: absolute;
  bottom: -10px;
  font-size: 1rem;
  font-weight: bolder;

  @media screen and (${theme.breakpoint.tablet.down}) {
    font-size: 0.6rem;
  }
`;

const LiveCircle = styled.div`
  border-radius: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  background-color: #00bb00;
  display: flex;
  justify-items: center;
  align-items: center;
  border-color: white;
  border-width: 5px;
  border-style: solid;

  @media screen and (${theme.breakpoint.tablet.down}) {
    width: 15px;
    height: 15px;
  }
`;

export function LiveCircleAd() {
  if (store.game.ad) {
  }

  return (
    <LiveCircleAdContainer>
      <a
        href="https://s.cant3am.com/d.php?z=334"
        target="_blank"
        rel="noreferrer"
        style={{
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          flexDirection: "column",
          textDecoration: "none",
          position: "relative",
          textAlign: "center",
        }}
      >
        <img
          alt="Live Chat Ad"
          src={LiveChatAdImage}
          style={{ width: "100%", height: "100%" }}
        />
        <LiveCopy variant="subtitle2">LIVE</LiveCopy>
        <LiveCircle />
      </a>
    </LiveCircleAdContainer>
  );
}
