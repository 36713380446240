import { delay } from "@/game/engine/delay";
import store from "@/store";

const SCROLLLER_ID =
  "https://wmptengate.com/pu/?target=pulfc&psid=scrolller&pstool=300_62&psprogram=cbrnd&campaign_id=128124&categoryName=girl&cobrandId=258376&site=wl3&width=1100&height=764&cap=24&ms_notrack=1&subAffId=%7BSUBAFFID%7D";

const SCROLLER_AD_GIRL =
  "https://wptcd.com/live-stream/fk/?c=object_container&site=wl3&cobrandId=258376&psid=scrolller&pstool=319_1&psprogram=cbrnd&campaign_id=128124&vp%5BshowChat%5D=true&vp%5BchatAutoHide%5D=true&vp%5BshowCallToAction%5D=true&vp%5BshowPerformerName%5D=true&vp%5BshowPerformerStatus%5D=true&subAffId=fapinstructor&categoryName=girl&embedTool=1&origin=";
const SCROLLER_AD_BOY =
  "https://wptcd.com/live-stream/fk/?c=object_container&site=wl3&cobrandId=258376&psid=scrolller&pstool=319_1&psprogram=cbrnd&campaign_id=128124&vp%5BshowChat%5D=true&vp%5BchatAutoHide%5D=true&vp%5BshowCallToAction%5D=true&vp%5BshowPerformerName%5D=true&vp%5BshowPerformerStatus%5D=true&subAffId=fapinstructor&categoryName=gay&embedTool=1&origin=";

export function openScrollerAd() {
  const handle = window.open(SCROLLLER_ID, "_blank");

  if (handle) {
    // @ts-expect-error global
    if (window.umami) {
      // @ts-expect-error global
      window.umami.track("successfully opened scroller ad");
    }
  } else {
    // @ts-expect-error global
    if (window.umami) {
      // @ts-expect-error global
      window.umami.track("failed to open scroller ad");
    }
  }
}

export const showScrollerAd = async (showWarmup?: boolean) => {
  const shoudlShowGayAd = Boolean(
    store.config.subreddits.find((r) => r.includes("gay") || r.includes("fag"))
  );

  // @ts-expect-error global
  window.umami?.track("showing scrolller ad");

  store.game.ad = shoudlShowGayAd ? SCROLLER_AD_BOY : SCROLLER_AD_GIRL;

  await delay(20_000);

  // @ts-expect-error global
  window.umami?.track("done showing scrolller ad");
  store.game.ad = null;
};
showScrollerAd.label = "Ad";

export const showScrollerWarmupAd = async () => {
  return showScrollerAd(true);
};
