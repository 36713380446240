import styled from "styled-components/macro";
/* eslint-disable react/jsx-no-target-blank */
// import { Paper, Typography } from "@material-ui/core";

import theme from "@/theme";

const AdIFrame = styled.iframe`
  width: 99%;
  height: 95%;
  // border: 2px solid white;
  // border-radius: 1rem;

  // @media screen and (${theme.breakpoint.tablet.down}) {
  //   height: 65vh;
  // }
`;

type AdProps = {
  src: string;
};

// const AdContainer = styled.div`
//   background-color: rgba(0, 0, 0, 0.4);
//   position: absolute;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100vw;
//   height: 100vh;
//   z-index: 1;
//   flex-direction: column;
//   gap: 1rem;
//
//   // @media screen and (${theme.breakpoint.tablet.down}) {
//   //   justify-content: flex-start;
//   //   margin-top: 1rem;
//   // }
// `;

export function Ad({ src }: AdProps) {
  // return (
  //   <AdContainer>
  //     <div
  //       style={{
  //         backgroundColor: "#gray",
  //         color: "white",
  //         textAlign: "center",
  //         marginInline: "1rem",
  //       }}
  //     >
  //       <Paper style={{ maxWidth: 600, padding: "1rem" }} elevation={9}>
  //         <Typography variant="h5">Ad Break</Typography>
  //         <Typography>
  //           Ads help support this free product, if you would like to enjoy an ad
  //           free experience sign up at{" "}
  //           <a target="_blank" href="https://www.fapchallenger.com">
  //             https://fapchallenger.com
  //           </a>
  //         </Typography>
  //       </Paper>
  //     </div>
  //   </AdContainer>
  // );

  return (
    <AdIFrame src={src} title="Advertisement" frameBorder="0" allowFullScreen />
  );
}
